




















import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import { VisualizationPanel, VisualizationManager, WordCloud } from "survey-analytics";
import { SurveyModel, Question } from "survey-vue";
import { PollSingle } from "@/types/interfaces";

import pollsVuexModule from "@/store/vuex-modules/polls";
import { PollResponse } from "@/types/interfaces";
const pollsStore = getModule(pollsVuexModule);

VisualizationManager.unregisterVisualizerForAll(WordCloud);

export default Vue.extend({
    data() {
        return {
            activePoll: "0",
            responsesInterval: undefined as number | undefined,
            resultsPanel: undefined as VisualizationPanel | undefined
        };
    },
    computed: {
        sessionId(): string {
            return this.$route.params.id;
        },

        polls(): Array<PollSingle> {
            return pollsStore.polls;
        },

        responses(): Array<PollResponse> {
            return pollsStore.singlePollResponses;
        }
    },
    watch: {
        async activePoll() {
            await this.initializeResults();
        }
    },
    mounted() {
        this.setup();
    },
    methods: {
        async setup(): Promise<void> {
            await this.initializePolls();
            await this.initializeResults();
        },
        async initializePolls(): Promise<void> {
            await pollsStore.getPollsForSession(this.sessionId);
        },

        async initializeResults(): Promise<void> {
            this.resultsPanel = undefined;
            const poll = this.polls[parseInt(this.activePoll)];
            const pollId = poll.pollId;

            this.clearResponsesInterval();

            await pollsStore.getSinglePollResponses({
                sessionId: this.sessionId,
                pollId: pollId
            });

            const analyticsOptions = {
                haveCommercialLicense: true,
                allowDynamicLayout: false,
                allowHideQuestions: false
            };

            const survey = new SurveyModel(poll);
            const questions = survey.getAllQuestions();
            const thisPollResponses = this.responses.map(
                (resp: PollResponse) => resp.answers
            );

            // HTML before questions can't be vizualized. This reduces results to just the question, so may be problematic?
            const filteredQuestions = questions.filter((question) => {
                if (!("html" in question)) {
                    return question;
                }
            });

            const dataTables = new VisualizationPanel(
                filteredQuestions,
                thisPollResponses,
                analyticsOptions
            );

            filteredQuestions.forEach((question: Question) => {
                const visualizer = dataTables.getVisualizer(question.name);
                const validTypes = ["bar", "pie", "doughnut", "scatter", "text"];
                const chartType =
                    poll.chartType && validTypes.includes(poll.chartType)
                        ? poll.chartType
                        : "pie";
                /**
                 * TODO: Fix explicit any. For some reason the VisualizerBase type this variable is set as throws
                 * a property does not exist error, despite the fact that this works fine.
                 */
                if(question.getType() != "text") {
                    (visualizer as any).setChartType(chartType);
                }
            });

            this.resultsPanel = dataTables;

            const container = document.getElementById("pollResultsPanel");

            if (container) {
                container.innerHTML = "";
                this.resultsPanel.render(container);
            }

            this.responsesInterval = window.setInterval(
                this.initializeResults,
                10000
            );
        },

        clearResponsesInterval(): void {
            window.clearInterval(this.responsesInterval);
        }
    }
});
